.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.notification-container {
  display: flex;
  flex-direction: column;
  height: 400px; /* Adjust as needed */
  width: 300px; /* Adjust as needed */
}

.nav-notification-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
}

.notification-list-wrapper {
  overflow-y: auto;
  flex: 1;
}

.list-clickable {
  position: relative;
  cursor: pointer;
}

.read-notification {
  background-color: #f0f0f0;
}

.notification-close-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 11px;
  color: #888;
}

.notification-close-icon:hover {
  color: #f5222d; /* Optional: Change color on hover */
}

.w-27 {
  width: 27px;
}

.no-wrap {
  text-wrap: nowrap;
}

.ant-menu-item-group-title {
  display: none !important;
}
