.signout_button button {
  width: 100%;
  height: 62px;
  border: none;
  color: #455560 !important;
  text-align: start;
  font-weight: 500;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  background-color: #fff !important;
  position: absolute;
  bottom: 0;
  border-radius: 0;
  padding: 0 24px;
}
[ant-click-animating-without-extra-node="true"]::after {
  box-shadow: none !important;
}
.active_card {
  outline: 2px solid #3e79f7;
  transition: all 0.1s ease-in-out;
}

.cursor_pointer {
  cursor: pointer;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.no-spinners[type="number"] {
  -moz-appearance: textfield;
}

.ant-tooltip-inner > span:nth-child(2) {
  margin-left: 5px !important;
}

/* book-an-appointment  */
.book_appointment_radio .ant-radio-inner {
  display: none; /* Hide the default radio circle */
}

/* .appointments_wrapper .ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
    font-weight: 400;
    font-size: 16px;
}
.appointments_wrapper .ant-table .ant-table-tbody .ant-table-row .ant-table-cell h5 {
    font-weight: 500;
    font-size: 16px;
}
.appointments_wrapper .table_img {
    border-radius: 50%;
    object-fit: cover;
}
.appointments_wrapper .tabel_icon {
    padding: 10px 11px 14px 11px;
    border-radius: 50% !important;
    background-color: #ebf1fe;
    color: #3f68f4;
}
.appointments_wrapper .tabel_icon svg {
    font-size: 16px;
}
.appointments_wrapper .tabel_icon:hover {
    background-color: #3f68f4 !important;
    color: #fff !important;
} */
.appointments_wrapper .ant-tabs-nav {
  position: fixed !important;
  top: 136px;
  z-index: 999;
  width: 100%;
  background-color: #fafafb;
}
.appointments_wrapper .appointments_heading {
  position: sticky !important;
  top: 90px;
  /* padding: 30px 0 0; */
  z-index: 999;
  background-color: #fafafb;
}
.appointments_wrapper .appointments_heading::before {
  content: "";
  position: absolute;
  top: -23px;
  width: 100%;
  border: 13px solid #fafafb;
}
.appointments_wrapper .ant-tabs-content-holder {
  padding: 40px 0 0;
}
.appointments_wrapper .ant-card {
  position: relative;
  border-radius: 0 10px 10px 0;
}
.appointments_wrapper .appointment_tooltip {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.cancel-tag {
  z-index: 99;
  top: 0px;
  left: 0px;
}
.appointments_wrapper .appointment_date {
  /* width: 55px;
    height: 60px; */
  justify-content: center;
  text-align: center;
  display: grid;
  background: #f0f0f5;
  border-radius: 4px;
  padding: 3px 8px;
}
.appointments_wrapper .appointment_date h1 {
  font-size: 22px;
  font-weight: 900;
}
.appointments_wrapper .appointment_date h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.appointments_wrapper button {
  /* border-radius: 4px;
    background-color: #fff;
    border: 1px solid #3e79f7;
    color: #3e79f7; */
}
.appointments_wrapper button:hover {
  /* border-radius: 4px !important; */
  /* background-color: #3e79f7 !important;
    border: 1px solid #3e79f7 !important;
    color: #fff !important; */
}
.appointments_wrapper .view_details {
  border-radius: 4px !important;
  background-color: #3e79f7 !important;
  border: 1px solid #3e79f7 !important;
  color: #fff !important;
}
.appointments_wrapper .ant-list-lg .ant-list-item {
  border-bottom: none !important;
  padding: 0 10px !important;
}
.content_container {
  height: calc(100vh - 340px) !important;
}
.slot_container {
  height: 100% !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dddcdc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9b9b9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* .slot_card .slot_wrapper {
    display: flex ;
    flex-direction: row ;
} */
.slot_card .slot_wrapper .ant-radio-wrapper {
  margin: 0;
}
@media (max-width: 1399px) {
  .slot_card .slot_wrapper {
    display: flex !important;
    flex-direction: column !important;
  }
  .slot_card .slot_wrapper .ant-radio-wrapper {
    margin: 0;
  }
}

/* find-doctor page popup css start */
.doctor_card {
  margin: 0;
}
.doctor_card .ant-card-body {
  padding: 10px;
}
.doctor_card img {
  object-fit: cover;
}
.doctor_card .job_heading {
  font-size: 22px;
  font-weight: 700;
  margin-top: 0;
}
.doctor_card .doctor_job {
  font-size: 16px;
  color: #3f68f4;
  font-weight: 400;
  margin: 0 0 5px;
}
.doctor_card .job_location {
  font-size: 16px;
  font-weight: 400;
  color: #455560;
  margin: 8px 0 0;
  line-height: 21px;
}
.doctor_card .job_detail {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin: 0 0 8px;
  line-height: 21px;
}
.doctor_card .job_detail1 {
  font-size: 16px;
  font-weight: 400;
  color: #455560;
  margin: 0 0 4px;
  line-height: 21px;
}
.doctor_list .ant-radio-group {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: auto;
}
.doctor_list .ant-radio-group::-webkit-scrollbar {
  height: 3px;
}
.book_button {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #3e79f7;
  color: #3e79f7;
}
.book_button:hover {
  background-color: #3e79f7;
  color: #fff;
}

/* find-doctor page popup css end */
.ant-radio-wrapper {
  margin: 8px 0px 8px 0px;
}
.button_container {
  position: absolute;
  bottom: 15px;
  right: 17px;
}
.step_2_form .ant-form-item-label {
  padding: 0;
}

.thank_you_page {
  height: 100vh;
}
.report_wrapper .report_icon {
  padding: 8px;
  border: 1px solid #ff6b72;
  border-radius: 6px;
  font-size: 16px;
  color: #ff6b72;
}
.add_icon {
  padding: 8px;
  border: 1px solid #699dff;
  border-radius: 6px;
  font-size: 16px;
  color: #699dff;
}
.report_heading h1 {
  font-size: 22px;
}

/* profile page css start */
.profile_img {
  position: relative;
}
.profile_img .anticon-edit {
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #fff;
  z-index: 99;
  font-size: 18px;
  background: #000000a6;
  padding: 8px;
  border-radius: 50px;
}

/* profile page css end */

/* .medicine_duration .ant-select {
    display: block;
}
.medicine_duration .ant-space-item {
    width: 50%;
} */

/* prescription details page css start */
.prescription_details .patient_detail h4 {
  font-size: 20px;
  margin-bottom: 0;
}
.prescription_details .patient_detail p {
  font-size: 17px;
}
/* prescription details page css end */

/* video call page css start */
.videocall_wrapper {
  background-color: #040404;
  /* height: 100vh; */
}
.videocall_wrapper .video_header {
  padding: 20px 0 20px;
  border-bottom: 1px solid #3a3c3f;
}
.videocall_wrapper .video_header button {
  font-size: 18px;
  padding: 15px;
  line-height: 1px;
  border-radius: 4px;
  background: #d0021b;
  border: 1px solid #d0021b;
}
/* .videocall_wrapper .video_screen {
    height: calc(100vh - 91px);
} */
.videocall_wrapper .video_screen .video_iframe {
  height: calc(100vh - 129px);
  width: 100%;
}
.videocall_wrapper .video_screen .screen_sidebar {
  height: calc(100vh - 97px);
  width: 100%;
  border-left: 1px solid #3a3c3f;
}
.videocall_wrapper .video_screen .screen_sidebar .nav-icon {
  font-size: 25px;
  color: #fff;
}
.videocall_wrapper .video_screen .screen_sidebar .nav-item {
  cursor: pointer !important;
  padding: 16px 0;
  background: #24282b;
  line-height: 11px;
}
.videocall_wrapper .video_screen .screen_sidebar .nav-item:hover {
  background: #515151;
  transition: all 0.2s ease-in-out;
}
/* video call page css end */
/* .book_appointment {
    background-color: #fff;
    border: 1px solid #3e79f7;
    color: #3e79f7;
}
.book_appointment:hover {
    background-color: #3e79f7 !important;
    border: 1px solid #3e79f7 !important;
    color: #fff !important;
    transition: all 0.2s ease-in-out !important;
} */

.tooltip__add_prescription {
  background: #303030;
  padding: 1px 8px;
  color: white;
  border-radius: 4px;
  margin: 0;
}

.ac-container label {
  height: 45px !important;
  line-height: 21px !important;
  font-size: 16px !important;
  padding: 12px 30px;
  position: relative;
  z-index: 20;
  display: block;
  height: 30px;
  border-radius: 10px;
  cursor: pointer;
  color: rgb(26, 51, 83);
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
  line-height: 33px;
  font-size: 19px;
  background: #fff;
  background: -moz-linear-gradient(top, #fff 1%, #eaeaea 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #fff), color-stop(100%, #eaeaea));
  background: -webkit-linear-gradient(top, #fff 1%, #eaeaea 100%);
  background: -o-linear-gradient(top, #fff 1%, #eaeaea 100%);
  background: -ms-linear-gradient(top, #fff 1%, #eaeaea 100%);
  background: linear-gradient(top, #fff 1%, #eaeaea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff',endColorstr='#eaeaea',GradientType=0 );
  box-shadow:
    0 0 0 1px rgba(155, 155, 155, 0.3),
    1px 0 0 rgba(255, 255, 255, 0.9) inset,
    0 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  height: 30px;
  line-height: 20px;
}

.ac-container {
  width: 100%;
  margin: 10px auto 30px auto;
  text-align: left;
}

.ac-container label:hover {
  background: #fff;
}

.ac-container input:checked + label,
.ac-container input:checked + label:hover {
  background: #fafafa;
  border-radius: 10px 10px 0 0;
  color: #666;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
  box-shadow:
    0 0 0 1px rgba(155, 155, 155, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.1);
  height: 30px;
  line-height: 21px;
  font-size: 13px;
}

.ac-container label:hover:after,
.ac-container input:checked + label:hover:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  right: 13px;
  top: 7px;
  /* background: transparent url("../images/arrow_down.png") no-repeat center center; */
}

.ac-container input:checked + label:hover:after {
  /* background-image: url("../images/arrow_up.png"); */
}

.radio_position {
  position: relative;
  margin: 0 0 15px 0;
}
.radio_position input {
  position: absolute;
  top: 16px;
  left: 10px;
  z-index: 9999;
}

.ac-container article {
  background: rgba(255, 255, 255, 0.5);
  margin-top: -1px;
  overflow: hidden;
  height: 0;
  position: relative;
  z-index: 10;
  border-radius: 0 0 10px 10px;
  -webkit-transition:
    height 0.3s ease-in-out,
    box-shadow 0.6s linear;
  -moz-transition:
    height 0.3s ease-in-out,
    box-shadow 0.6s linear;
  -o-transition:
    height 0.3s ease-in-out,
    box-shadow 0.6s linear;
  -ms-transition:
    height 0.3s ease-in-out,
    box-shadow 0.6s linear;
  transition:
    height 0.3s ease-in-out,
    box-shadow 0.6s linear;
}

/* .ac-container article p {
    font-style: normal;
    color: #777;
    line-height: 23px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(255,255,255,0.8);
} */

.ac-container input:checked ~ article {
  -webkit-transition:
    height 0.5s ease-in-out,
    box-shadow 0.1s linear;
  -moz-transition:
    height 0.5s ease-in-out,
    box-shadow 0.1s linear;
  -o-transition:
    height 0.5s ease-in-out,
    box-shadow 0.1s linear;
  -ms-transition:
    height 0.5s ease-in-out,
    box-shadow 0.1s linear;
  transition:
    height 0.5s ease-in-out,
    box-shadow 0.1s linear;
  box-shadow: 0 0 0 1px rgba(155, 155, 155, 0.3);
}

.ac-container input:checked ~ article.ac-small {
  height: 140px;
}

.ac-container input:checked ~ article.ac-medium {
  height: 335px;
  padding: 16px;
}

.ac-container input:checked ~ article.ac-large {
  height: 100%;
  padding: 16px;
}

/* disable table row  */
.disable_row {
  opacity: 0.6;
  color: rgb(240, 240, 240);
  background-color: rgba(219, 219, 219, 0.199);
}

.patient_profile .allergy_list {
  padding: 14px 16px;
  border: 1px solid #e9e9e9;
  margin-top: 5px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);

  /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.patient_profile .allergy_list .allergy {
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-transform: capitalize;
}
.patient_profile .allergy_list .allergy .allergy_value {
  flex: 1;
}

.patient_profile .allergy_list .allergy .allergy_level {
  margin: 0 16px;
}

.tnc ol .user::marker {
  font-size: 22px;
}

.otp_field .ant-input {
  letter-spacing: 25px !important;
  text-align: center !important;
}

.otp_field .ant-input::placeholder {
  text-align: left !important;
  letter-spacing: normal !important;
}
.login_option label {
  width: 50%;
  text-align: center;
}

.patient_profile .mobile_number .ant-form-item-label {
  padding: 3px 0;
}

.availability-row .ant-card-body {
  padding: 8px 15px !important;
}

.availability-row .ant-form-item {
  margin: 0 0 6px 0 !important;
}

.custom_required .ant-form-item-label {
  position: relative;
  margin-left: 10px;
}

.custom_required::before {
  content: "*";
  position: absolute;
  margin-right: 5px;
  top: 1px;
  left: 8px;
  color: #ff6b72a4;
}
